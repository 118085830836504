import React from 'react';

import CashAdvanceIcon from '../../../../images/CashAdvance';
import CoursesIcon from '../../../../images/Courses';
import CreditCardsIcon from '../../../../images/CreditCards';
import DebtIcon from '../../../../images/Debt';
import EarnMoneyIcon from '../../../../images/EarnMoney';
import InsuranceIcon from '../../../../images/Insurance';
import LoansIcon from '../../../../images/Loans';

import style from './Hero.scss';

export const list = [
  {
    href: '/extra-money/',
    icon: <EarnMoneyIcon />,
    title: 'Earn Money',
    target: undefined,
  },
  {
    href: '/insurance/',
    icon: <InsuranceIcon />,
    title: 'Insurance',
    target: undefined,
  },
  {
    href: '/credit-cards/',
    icon: <CreditCardsIcon />,
    title: 'Credit Cards',
    target: undefined,
  },
  {
    href: '/cash-advance/',
    icon: <CashAdvanceIcon />,
    title: 'Cash Advance',
    target: undefined,
  },
  {
    href: '/debt/',
    icon: <DebtIcon />,
    title: 'Debt',
    target: undefined,
  },
  {
    href: '/loans/',
    icon: <LoansIcon />,
    title: 'Loans',
    target: undefined,
  },
  {
    href: '/course/',
    icon: <CoursesIcon />,
    title: 'Courses',
    target: undefined,
  },
];

const HeroA = () => {
  return (
    <section className={style.hero}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>
              Navigate life on your terms
              <small>Compare rates and get expert guidance on all things finance</small>
            </h1>
            <div className={style.items}>
              {list.map((item) => (
                <a key={item.href} href={item.href} target={item.target}>
                  {item.icon}
                  <h4>{item.title}</h4>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroA;
